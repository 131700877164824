body[dir=rtl] .page_content {
    text-align: right;
}
body[dir=rtl] .mr-auto, .mx-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
}

body[dir=rtl] .ml-auto, .mx-auto {
    margin-left: 0 !important;
    margin-right: auto !important;
}

body[dir=rtl] .sidebar-column {
    text-align: right;
}

body[dir=rtl] .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
    padding-right: 0.5rem;
}

body[dir=rtl] .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0;
    padding-left: 0.5rem;
}

body[dir=rtl] .ml-2, .mx-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
}

body[dir=rtl] .form-group {
    text-align: right;
}

body[dir=rtl] .modal-header .close {
    margin: -1rem auto -1rem -1rem;
}

body[dir=rtl] .modal-footer .btn + .btn {
    margin-right: auto;
    margin-left: 5px;
}

body[dir=rtl] .footer-col-left {
    text-align: right;
}
body[dir=rtl] .footer-col-right {
    text-align: left;
}

body[dir=rtl] .list-unstyled {
    text-align: right;
    padding-right: initial;
    padding-left: 0;
}

body[dir=rtl] .website-list {
    text-align: right;
}

body[dir=rtl] .labelled-dropdown {
    text-align: right;
}

body[dir=rtl] .filter-list {
    text-align: right;
}

body[dir=rtl] .page_content > h1 {
    text-align: right;
}

body[dir=rtl] .datepicker:not(.active) {
    left: auto!important;
    right: -100000px;
}

body[dir=rtl] .page-header {
    text-align: right;
}

body[dir=rtl] .accordion .card-header > h3 > button {
    text-align: right!important;
}

body[dir=rtl] .btn-group > .btn:last-child:not(:first-child), body[dir=rtl] .btn-group > .dropdown-toggle:not(:first-child) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

body[dir=rtl] .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

body[dir=rtl] .fc-button-group .btn-group > .btn:last-child:not(:first-child), body[dir=rtl] .fc-button-group .btn-group > .dropdown-toggle:not(:first-child) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

body[dir=rtl] .fc-button-group .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

body:not([dir=rtl]) .dropdown-menu.show {
    left:auto!important;
    right:0!important;
}


























.frappe-rtl .search-bar .awesomplete input {
    padding: 0.375rem 0.75rem;
    padding-right: 36px;
}

.frappe-rtl .search-bar .search-icon {
    margin-left: 0;
    margin-right: 12px;
}

.frappe-rtl .dropdown-menu-right {
    right: auto;
    left: 0;
}

.frappe-rtl .standard-sidebar-label {
    text-align: right;
}

.frappe-rtl .desk-sidebar-item.standard-sidebar-item span svg {
    margin-left: var(--margin-sm);
    margin-right: 0;
}

.frappe-rtl .widget-title svg {
    margin-left: 6px;
    margin-right: 0;
}

.frappe-rtl .widget-subtitle {
    text-align: right;
}

.frappe-rtl .widget.links-widget-box .link-item .indicator-pill {
    margin-left: var(--margin-sm);
    margin-right: 0;
}

.frappe-rtl .widget.links-widget-box .link-item .link-content {
    text-align: right;
}

.frappe-rtl .recent-item.notification-item {
    padding: 10px 4px 10px 10px;
}

.frappe-rtl .recent-item.notification-item .notification-body::before {
    margin-left: var(--margin-xs);
    margin-right: 0;
}

.frappe-rtl .recent-item.notification-item .user-avatar {
    margin-left: 10px;
    margin-right: 0;
}

.frappe-rtl .awesomplete > ul > li {
    text-align: right;
}

.frappe-rtl .modal .modal-header .modal-actions {
    left: 5px;
    right: auto;
}

.frappe-rtl #alert-container .table th, .frappe-rtl .table td {
    text-align: right;
}

.frappe-rtl #alert-container .desk-alert .alert-message-container {
    padding: var(--padding-md);
    padding-left: var(--padding-2xl);
}

.frappe-rtl #alert-container .alert-title-container .icon {
    margin-left: var(--margin-sm);
    margin-right: 0;
}

.frappe-rtl #alert-container .desk-alert .close {
    left: var(--padding-md);
    right: auto;
}

.frappe-rtl .widget.onboarding-widget-box .widget-body .onboarding-step-preview {
    text-align: right;
    padding-right: var(--padding-lg);
    padding-left: 0;
}

.frappe-rtl .widget .widget-head .widget-control > * {
    margin-right: 5px;
    margin-left: 0;
}

.frappe-rtl .indicator-pill::before, .frappe-rtl .indicator-pill-right::after {
    margin-left: 6px;
    margin-right: 0;
}

.frappe-rtl .widget-body .indicator-pill::before {
    margin-left: 0;
}

.frappe-rtl .widget.onboarding-widget-box .widget-body .onboarding-step .step-index {
    margin-left: var(--margin-sm);
    margin-right: 0;
}

.frappe-rtl .modal-body > div {
    text-align: right;
}

.frappe-rtl .page-actions .btn, .frappe-rtl .page-actions div#driver-popover-item .driver-popover-footer button, .frappe-rtl div#driver-popover-item .driver-popover-footer .page-actions button {
    margin-right: var(--margin-sm, 8px);
    margin-left: 0;
}

.frappe-rtl .layout-side-section .overlay-sidebar {
    text-align: right;
}

.frappe-rtl .form-group {
    text-align: right;
}

.frappe-rtl .frappe-control[data-fieldtype="Select"] .control-input .select-icon.xs, .frappe-rtl .frappe-control[data-fieldtype="Select"].form-group .select-icon.xs {
    left: 10px;
    right: auto;
}

.frappe-rtl .frappe-control[data-fieldtype="Select"] .control-input .select-icon, .frappe-rtl .frappe-control[data-fieldtype="Select"].form-group .select-icon {
    left: 12px;
    right: auto;
}

.frappe-rtl .frappe-control[data-fieldtype="Select"] .control-input .placeholder, .frappe-rtl .frappe-control[data-fieldtype="Select"].form-group .placeholder {
    right: 12px;
    left: auto;
}

.frappe-rtl .radio label, .frappe-rtl .checkbox label {
    padding-right: initial;
    padding-left: 20px;
}

.frappe-rtl .list-subject .level-item {
    margin-left: 8px;
    margin-right: 0;
}

.frappe-rtl input[type="checkbox"] {
    margin-left: var(--checkbox-right-margin) !important;
    margin-right: 0 !important;
}

.frappe-rtl .list-row-col {
    margin-left: 15px;
    margin-right: 0;
    text-align: right;
}

.frappe-rtl .list-row .list-row-activity > span:not(:last-child), .frappe-rtl .list-row-head .list-row-activity > span:not(:last-child) {
    margin-left: 15px;
    margin-right: 0;
}

.frappe-rtl .list-count {
    margin-left: var(--margin-sm);
    margin-right: 0%;
}

.frappe-rtl .page-title .title-area .title-text {
    margin-left: var(--margin-sm);
    margin-right: 0;
}

.frappe-rtl .page-title .sidebar-toggle-btn {
    margin-left: var(--margin-sm);
    margin-right: 0;
}

.frappe-rtl .layout-side-section .sidebar-label .icon {
    margin: 0;
    margin-left: var(--margin-xs);
}

.frappe-rtl .form-section .section-head, .frappe-rtl .form-dashboard-section .section-head {
    text-align: right;
}

.frappe-rtl .datepicker {
    direction: ltr;
}

.frappe-rtl .grid-row > .row .col:last-child {
    margin-right: -10px;
    margin-left: auto;
}

.frappe-rtl .grid-body .btn-open-row div {
    margin-right: var(--margin-xs);
    margin-left: 0;
}

.frappe-rtl .grid-header-toolbar .row-actions button:not(:last-child) {
    margin-right: var(--margin-xs);
    margin-left: 0;
}

.frappe-rtl .form-in-grid .form-section .form-column:first-child {
    padding-right: 0;
}

.frappe-rtl .after-save {
    text-align: right;
}

.frappe-rtl .new-timeline .timeline-dot {
    right: calc(-1 * (16px / 2));
    left: auto;
}

.frappe-rtl .new-timeline .timeline-actions .action-btn:first-of-type {
    margin-right: var(--timeline-item-left-margin);
    margin-left: 0;
}

.frappe-rtl .new-timeline .timeline-actions .action-btn .icon {
    margin-left: var(--margin-xs);
    margin-right: 0;
}

.frappe-rtl .new-timeline {
    padding-right: var(--timeline-left-padding);
    padding-left: 0;
}

.frappe-rtl .new-timeline .timeline-item .timeline-content {
    margin-right: var(--timeline-item-left-margin);
    margin-left: 0;
}

.frappe-rtl .form-footer .scroll-to-top {
    right: auto;
    left: 0;
}

.frappe-rtl .page-actions .standard-actions > .page-icon-group {
    direction: ltr;
}

.frappe-rtl .menu-btn-group .menu-item-label, .frappe-rtl .custom-btn-group .menu-item-label, .frappe-rtl .page-icon-group .menu-item-label {
    margin-left: var(--margin-md);
    margin-right: 0;
}

@media (min-width: 992px) {
    .frappe-rtl [data-page-route="Workspaces"] .layout-main .layout-side-section, .frappe-rtl [data-page-route="Workspaces"] .layout-main .layout-main-section-wrapper {
        padding-left: 25px;
        padding-right: 15px;
    }

    .frappe-rtl [data-page-route="Workspaces"] .layout-main .layout-side-section {
        margin-left: 20px;
        margin-right: 0;
    }
}

.frappe-rtl .pull-right {
    float: left;
}

.frappe-rtl .pull-left {
    float: right;
}

.frappe-rtl .form-links .document-link .btn-new, .frappe-rtl .form-links .document-link .open-notification {
    margin-right: var(--margin-sm);
    margin-left: 0;
}

.frappe-rtl .form-links .document-link .document-link-badge .count, .frappe-rtl .form-links .document-link .report-link-badge .count {
    margin-left: var(--margin-xs);
    margin-right: 0;
}

.frappe-rtl .form-links .form-link-title {
    text-align: right;
}

@media (min-width: 768px) {
    .frappe-rtl .layout-main .form-column.col-sm-12 > form > .input-max-width {
        padding-left: 15px;
        padding-right: 0;
    }
}

.frappe-rtl .module-block-list {
    text-align: right;
}

.frappe-rtl .frappe-control[data-fieldtype="MultiCheck"] {
    text-align: right;
}

.frappe-rtl .file-icon {
    margin-left: var(--margin-md);
    margin-right: 0;
}

.frappe-rtl .ml-2, .frappe-rtl .mx-2 {
    margin-right: 7px !important;
}

.frappe-rtl .file-actions {
    margin-right: auto;
    margin-left: unset;
}